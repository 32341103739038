<template>
  <div class="miro uk-flex uk-flex-middle uk-flex-center">
    <iframe
      v-if="boardId"
      width="90%"
      height="90%"
      :src="`//miro.com/app/live-embed/${boardId}`"
      frameBorder="0"
      scrolling="no"
      allowFullScreen
    />

    <!-- Заглушка если доска не создана -->
    <div
      v-else-if="user.role === 0 || user.role === 1"
      class="plug uk-flex uk-flex-middle uk-flex-center"
    >
      <div v-if="!isNewFormOpen && !isImportFormOpen" class="buttons">
        <button class="uk-padding uk-margin-right" @click="isNewFormOpen = true">
          {{ $t("Miro.buttons.create") }}
        </button>
        <!-- <button class="uk-padding" @click="isImportFormOpen = true">Импорт</button> -->
      </div>

      <!-- Форма создания новой доски -->
      <form
        v-if="isNewFormOpen"
        class="uk-flex uk-flex-column uk-flex-between uk-padding"
        @submit.prevent="handleCreateBoard"
      >
        <div class="uk-flex uk-flex-middle uk-margin">
          <b class="uk-margin-right">{{ $t("Miro.createForm.title") }}</b>
          <button type="buttom" uk-close @click="isNewFormOpen = false"></button>
        </div>

        <label for="boardName">{{ $t("Miro.createForm.name") }}</label>
        <input id="boardName" v-model="boardParams.name" type="text" class="uk-margin-small" />
        <label for="boardDescription">{{ $t("Miro.createForm.description") }}</label>
        <textarea
          id="boardDescription"
          v-model="boardParams.description"
          class="uk-margin-small"
          rows="5"
        />
        <ws-button type="submit" color="primary">{{ $t("Miro.createForm.sendButton") }}</ws-button>
      </form>
      <!-- Форма создания новой доски END -->

      <!-- Форма импорта доски -->
      <!-- <form
        v-if="isImportFormOpen"
        class="uk-flex uk-flex-column uk-padding"
        @submit.prevent="testImport"
      >
        <div class="uk-flex uk-flex-middle uk-flex-between uk-margin">
          <b class="uk-margin-right">Форма импорта новой доски:</b>
          <button type="buttom" uk-close @click="isImportFormOpen = false"></button>
        </div>

        <div class="js-upload uk-placeholder uk-text-center">
          <span uk-icon="icon: cloud-upload"></span>
          <span class="uk-text-middle"> Перетащите или </span>
          <div uk-form-custom>
            <TODO: Может тут не нужен ref>
            <input ref="importFile" type="file" multiple />
            <span class="uk-link">выберите один</span>
          </div>
        </div>
        <ws-button type="submit" color="primary">Отправить</ws-button>
      </form> -->
      <!-- Форма импорта доски END -->
    </div>
    <!-- Заглушка если доска не создана END -->

    <div v-else class="uk-text-center">
      {{ $t("Miro.plugText") }}
    </div>
  </div>
</template>

<script>
import { miro, auth, room } from "@/store/modules/store.namespaces";
import { mapActions, mapState } from "vuex";
import WsButton from "@/components/base/WsButton";

export default {
  name: "Miro",

  components: {
    WsButton,
  },

  data() {
    return {
      // Открыта ли форма создания новой доски
      isNewFormOpen: false,
      // Открыта ли форма импорта доски
      isImportFormOpen: false,
      // Параметры создаваемой доски (Подробнее: https://developers.miro.com/reference/create-board)
      boardParams: {
        name: "Новая доска",
        description: "Описание",
        sharingPolicy: { access: "comment", teamAccess: "edit" },
      },
    };
  },

  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(room, ["roomInfo", "roomNumber"]),
    // ID доски миро
    boardId() {
      return this.roomInfo?.miro?.boardId;
    },
  },

  methods: {
    ...mapActions(miro, ["createBoard"]),
    // Метод сабмита формы
    handleCreateBoard() {
      const { Presentation: presentationId } = this.roomInfo;
      const payload = {
        roomNumber: this.roomNumber,
        boardParams: this.boardParams,
        presentationId,
      };

      this.createBoard(payload);
    },
    // Тестовый импорт бэкапа
    // TODO: Доделать!
    // testImport(event) {
    //   // https://learn.javascript.ru/formdata - доделать
    //   // Апи на создание доски "https://miro.com/api/v1/boards/?accountId=3074457367280296933&_=1636998320396"
    //   const formData = new FormData();
    //   const input = this.$refs.importFile;
    //   // Перенести это все в new FormData
    //   formData.append("file", input?.files[0], "file.rtb");

    //   axios
    //     .post(
    //       "https://miro.com/api/v1/boards/?accountId=3074457367280296933&_=1636998320396",
    //       formData,
    //     )
    //     .then(res => console.log(res))
    //     .catch(er => console.log(er));
    //   // axios.post("https://miro.com/api/v1/boards/?accountId=3074457367280296933&_=1636998320396", );
    // },
  },
};
</script>

<style lang="scss">
.miro {
  width: 100%;
  height: 100%;

  /* Стили заглушки */
  .plug {
    background: #f2f2f2;
    width: 100%;
    height: 100%;

    .buttons {
      button {
        border: none;
        border-radius: 5px;
        box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
        background: #fff;
      }
    }

    form {
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

      input {
        border: none;
      }

      textarea {
        resize: none;
      }
    }
  }
  /* Стили заглушки END */
}
</style>
